import React from 'react'

const Inquiry = () => {
  return (
    <section className="py-0 bg-dark" id="inquiries">
      <div className="container bg-primary">
        <div className="max-w-3xl">
          <h2 className="border-b-2 border-red-400">Inquiries</h2>
          <p>If you wish to contact us about this project or corpus, please address your email to <a href="mailto:amathur@princeton.edu,angelina.wang@princeton.edu,c.schwem2er@gmail.com,maiahamin@gmail.com,bms4@princeton.edu,arvindn@cs.princeton.edu">all of us.</a></p>
        </div>
      </div>
    </section>
  )
}

export default Inquiry
